import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const PayNowButton = styled.button`
    border: none;
    margin: 0;
    width: auto;
    overflow: visible;

    background: transparent;
    font-weight: 700;

    color: #cc3700;
    font: inherit;

    line-height: normal;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;

    &:hover {
        cursor: pointer;
    }

    opacity: ${props => props.disabled ? 0.5 : 1};
`;

const PayUButton = ({ text, disabled, amount = '50000', productName}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const submitPayU = async e => {
        setLoading(true);
        e.preventDefault();
        const response = await axios.post('/.netlify/functions/payuEur', { amount, productName });
        setLoading(false);
        if (response.error) setError('There was an error processing the request, please use the contact form');
        window.open(response.data.redirectUri);
    };

    return (
        <div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <PayNowButton onClick={submitPayU} disabled={error || disabled} className="btn">
                {loading ? 'Please Wait' : text ? text : 'Pay Now'}
            </PayNowButton>
        </div>
    );
};

export default PayUButton;
